// function to create request
async function createRequest(httpMethod, api, requestValues) {
  const requestOptions = {
    method: httpMethod,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestValues)
  };
  const response = await fetch(api, requestOptions);
  const data = await response.json();
  return data;
}

// function to fetch PUT approve create/reset deployments API
async function approveRequest(api, requestValues) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestValues)
  };
  const response = await fetch(api, requestOptions);
  const data = await response.json();
  return data;
}

// function to fetch PUT approve delete group API
async function deleteRequest(api, requestValues) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestValues)
  };
  const response = await fetch(api, requestOptions);
  const data = await response.json();
  return data;
}

export { createRequest, approveRequest, deleteRequest };
