import React from "react";
import MaterialTable from "material-table";
import useAuthCognitoGroup from "../custom_hooks/useAuthCognitoGroup";

const CreateDeploymentsApprovalTable = props => {
  const isSuperadmin = useAuthCognitoGroup("superadmin");

  return (
    <div>
      <MaterialTable
        columns={[
          { title: "Request ID", field: "request_id" },
          {
            title: "Deployment Type",
            field: "deployment_type"
          },
          {
            title: "Requested At",
            field: "requestedAt"
          },
          { title: "Request User", field: "request_user" }
        ]}
        data={props.data}
        title="Server Deployment Requests"
        actions={
          isSuperadmin && [
            // only superadmin have access to create and reject actions
            {
              icon: "check",
              tooltip: "Approve",
              onClick: (event, rowData) => {
                props.handleApproveAction(
                  rowData.request_id,
                  rowData.deployment_type
                );
              }
            },
            {
              icon: "close",
              tooltip: "Reject",
              onClick: (event, rowData) => {
                props.handleRejectAction(rowData.request_id);
              }
            }
          ]
        }
        options={{
          actionsColumnIndex: -1
        }}
        detailPanel={rowData => {
          const { groups } = rowData;
          return (
            <MaterialTable
              columns={[
                { title: "No. ", render: row => row.tableData.id + 1 }, // auto row id by material-table + 1
                { title: "Group", field: "name" },
                { title: "Group ID", field: "groupId" },
                { title: "New ANPR Model Path", field: "anprS3Uri" },
                { title: "New VC Model Path", field: "vcS3Uri" }
              ]}
              data={groups}
              options={{
                search: false,
                selection: false,
                padding: "dense",
                pageSize: groups.length,
                paging: false,
                toolbar: false
              }}
            />
          );
        }}
      />
    </div>
  );
};

export default CreateDeploymentsApprovalTable;
