// 5 form fields: Username, role, password, confirm password, email.

import React from "react";
import { useFormik } from "formik";
import { Paper, TextField, Button, Select } from "@material-ui/core";

// placeholder will remove
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const UserManagementFormDialog = props => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/;
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const formik = useFormik({
    initialValues: {
      username: props.username,
      role: props.userRole, // old role, obtained from user table.
      newRole: props.userRole, // new role after selecting from form (if edit action)
      password: "",
      confirmPassword: "",
      email: props.userEmail
    },
    onSubmit: (values, { resetForm }) => {
      // alert(JSON.stringify(values, null, 2));
      console.log(values);
      props.handleSubmit(
        values.username,
        values.email,
        values.password,
        values.newRole
      );
      // resets form after submitting
      resetForm();

      // handleclose
      props.handleClose();
    },
    validate: values => {
      const errors = {};
      if (!values.username) {
        errors.username = "Required";
      }
      if (!values.newRole) {
        errors.newRole = "Required";
      }

      if (props.isCreate) {
        if (!values.password) {
          errors.password = "Required";
        } else if (!passwordRegex.test(values.password)) {
          errors.password =
            "Password must have atleast 1 lowercase, 1 uppercase, 1 digit, 1 special character and at least 8 characters long.";
        }

        if (!values.confirmPassword) {
          errors.confirmPassword = "Required";
        } else if (values.confirmPassword !== values.password) {
          errors.confirmPassword =
            "Confirm password does not match password above.";
        }
      }

      if (!values.email) {
        errors.email = "Required";
      } else if (!emailRegex.test(values.email)) {
        errors.email = "Please enter a valid email address format";
      }
      return errors; // must return an object
    }
  });

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">User Management Form</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <DialogContentText>
            Update User's Information or Create New User below.
          </DialogContentText>
          <div>
            <TextField
              id="username"
              label="username"
              name="username"
              type="text"
              onChange={props.isCreate ? formik.handleChange : null}
              value={formik.values.username}
              onBlur={formik.handleBlur}
              fullWidth
              margin="dense"
            />
            {formik.touched.username && formik.errors.username ? (
              <div>{formik.errors.username}</div>
            ) : null}
          </div>
          <br />
          <Select
            native
            name="newRole"
            value={formik.values.newRole}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
          >
            <option aria-label="None" value="">
              Select a role
            </option>
            <option value="superadmin">Admin</option>
            <option value="normal-user">Normal User</option>
            <option value="read-only">Read-only</option>
          </Select>
          {formik.touched.newRole && formik.errors.newRole ? (
            <div>{formik.errors.newRole}</div>
          ) : null}
          <div>
            <TextField
              id="password"
              label="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              onBlur={formik.handleBlur}
              fullWidth
              margin="dense"
            />
            {formik.touched.password && formik.errors.password ? (
              <div>{formik.errors.password}</div>
            ) : null}
          </div>
          <div>
            <TextField
              id="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              onBlur={formik.handleBlur}
              fullWidth
              margin="dense"
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div>{formik.errors.confirmPassword}</div>
            ) : null}
          </div>
          <div>
            <TextField
              id="email"
              label="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              fullWidth
              margin="dense"
            />
            {formik.touched.email && formik.errors.email ? (
              <div>{formik.errors.email}</div>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.handleClose();
              formik.resetForm();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="contained"
            onClick={() => {
              props.handleClose();
              // pass a function, which:
              // gets user, remove user from existing group, add to new group(selected in form), update user attributes.
              props.handleEdit(
                formik.values.username,
                formik.values.email,
                formik.values.password,
                formik.values.role,
                formik.values.newRole
              );
            }}
            disabled={!(formik.isValid && formik.dirty && props.isEdit)}
          >
            Update User Information
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!(formik.isValid && formik.dirty && props.isCreate)}
          >
            Create New User
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserManagementFormDialog;
