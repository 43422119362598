import React from "react";
import MaterialTable from "material-table";

const LatestModelPathsTable = props => (
  <div>
    <MaterialTable
      title="Latest Model Paths Of Greengrass Groups"
      columns={[
        {
          title: "Group Name",
          field: "groupName",
          align: "center"
        },
        {
          title: "Last Updated Date & Time",
          field: "createdAt",
          align: "right",
          defaultSort: "desc"
        },
        { title: "Resource Definition", field: "id", align: "right" },
        {
          title: "Latest Resource Definition Version",
          field: "version",
          align: "right"
        },
        { title: "ANPR Model Path", field: "anprS3Uri", align: "right" },
        { title: "VC Model Path", field: "vcS3Uri", align: "right" }
      ]}
      data={props.data}
      options={{
        padding: "dense",
        pageSize: 25,
        pageSizeOptions: [10, 25, 50]
      }}
    />
  </div>
);
export default LatestModelPathsTable;
