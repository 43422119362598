import React, { useState } from "react";
import MaterialTable from "material-table";

import PermissionDeniedDialog from "./permissionDeniedDialog";
import DumbConfirmationDialog from "./DumbConfirmationDialog";
import ApiPopupDialog from "./ApiPopupDialog";

const ReleaseApprovalTable = props => {
  // to open permission dialog or not
  const [open, setOpen] = useState(false);
  const [requestData, setRequestData] = useState({});

  // to determine if approve or reject button is clicked
  const [isApprove, setIsApprove] = useState(false);

  // hooks for ApiPopupDialog and apiFetching
  const [showApiPopup, setShowApiPopup] = useState(false);
  const [apiFetching, setApiFetching] = useState(false);

  return (
    <div>
      <MaterialTable
        columns={[
          { title: "Repository", field: "repository" },
          { title: "Tag Version", field: "tagVersion" },
          { title: "Title", field: "title" },
          { title: "Target Branch", field: "targetBranch" },
          { title: "Body", field: "body" },
          {
            title: "Requested At",
            field: "requestedAt"
          },
          { title: "Author", field: "author" }
        ]}
        data={props.data}
        title="Github Release Requests"
        actions={[
          {
            icon: "check",
            tooltip: "Approve",
            onClick: (event, rowData) => {
              setOpen(true);
              setRequestData({
                // repo and tagVersion to update request in db;
                // title, body and targetBranch as params in lambda's github release api call.
                repository: rowData.repository,
                tagVersion: rowData.tagVersion,
                title: rowData.title,
                body: rowData.body,
                targetBranch: rowData.targetBranch
              });
              setIsApprove(true);
            }
          },
          {
            icon: "close",
            tooltip: "Reject",
            onClick: (event, rowData) => {
              setOpen(true);
              setRequestData({
                // repo and tagVersion to delete request from db based on composite primary key
                repository: rowData.repository,
                tagVersion: rowData.tagVersion
              });
              setIsApprove(false);
            }
          }
        ]}
        options={{
          actionsColumnIndex: -1
        }}
      />

      {props.hasPermission && (
        <DumbConfirmationDialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          onConfirm={() => {
            if (isApprove) {
              setApiFetching(true);
              setShowApiPopup(true);
              props.handleApprove(requestData).then(data => {
                setApiFetching(false);
                console.log(
                  "data returned in handleApprove in confirmation Dialog:",
                  data
                );
              });
            } else {
              setApiFetching(true);
              setShowApiPopup(true);
              props.handleReject(requestData).then(data => {
                setApiFetching(false);
                console.log(
                  "data returned in handleReject in confirmation Dialog:",
                  data
                );
              });
            }
          }}
          title="Github Release Approval"
          question={isApprove ? "approve this request" : "reject this request"}
        />
      )}

      {!props.hasPermission && (
        <PermissionDeniedDialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}

      {/* sets to open in the confirmation dialog */}
      {showApiPopup && (
        <ApiPopupDialog
          open={showApiPopup}
          onClose={() => {
            setShowApiPopup(false);
            // window.location.reload(false);
            props.setIsLoading(true);
            props.setRawData(false);
          }}
          apiFetching={apiFetching}
          completeMessage={
            isApprove ? "Release Request Approved" : "Release Request Rejected"
          }
        />
      )}
    </div>
  );
};

export default ReleaseApprovalTable;
