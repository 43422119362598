import React, { useState } from "react";
import { LinearProgress } from "@material-ui/core";
import { useSubheader } from "../../_metronic/layout";
import useFetch from "../custom_hooks/useFetch";
import LoadingDialog from "../custom_ui_components/LoadingDialog";
import ErrorDialog from "../custom_ui_components/ErrorDialog";
import useAuthCognitoGroup from "../custom_hooks/useAuthCognitoGroup";
import DumbConfirmationDialog from "../custom_ui_components/DumbConfirmationDialog";
import ApiPopupDialog from "../custom_ui_components/ApiPopupDialog";
import { SERVER_DEPLOYMENTS_API } from "../server_deployments_/helpers/serverDeploymentsAPI";
import {
  deleteRequest,
  approveRequest
} from "../server_deployments_/helpers/serverApiHelpers";
import CreateDeploymentsApprovalTable from "../server_deployments_/CreateDeploymentsApprovalTable";

const ServerDeploymentsApprovalPage = () => {
  // determines user permission based on cognito group
  const isSuperadmin = useAuthCognitoGroup("superadmin");
  const permissionAllowed = isSuperadmin;

  const suhbeader = useSubheader();
  suhbeader.setTitle("Server Deployments Request Approval Page");

  // GET unapproved releases
  const { rawData, isLoading, error, setIsLoading, setRawData } = useFetch(
    SERVER_DEPLOYMENTS_API
  );

  // show confirmation dialog or not
  const [
    openApproveConfirmationDialog,
    setApproveConfirmationDialogOpen
  ] = useState(false);
  const [
    openRejectConfirmationDialog,
    setRejectConfirmationDialogOpen
  ] = useState(false);

  // api fetch dialog useState hook
  const [showApiPopup, setShowApiPopup] = useState(false);
  const [apiFetching, setApiFetching] = useState(false);
  const [apiResponse, setApiResponse] = useState("");

  // store row's requestId and deploymentType
  const [rowRequestId, setRowRequestId] = useState("");
  const [deploymentType, setDeploymentType] = useState("");

  // handles showing "approve" and "reject" confirmation dialog for
  const handleApproveConfirmationDialogOpen = () => {
    setApproveConfirmationDialogOpen(true);
  };

  const handleApproveConfirmationDialogClose = () => {
    setApproveConfirmationDialogOpen(false);
  };

  const handleRejectConfirmationDialogOpen = () => {
    setRejectConfirmationDialogOpen(true);
  };

  const handleRejectConfirmationDialogClose = () => {
    setRejectConfirmationDialogOpen(false);
  };

  // the below 2 methods that actually calls the API endpoints
  const handleApproveConfirmation = () => {
    console.log("deployment type is: ", deploymentType);
    setApiFetching(true);
    setShowApiPopup(true);
    approveRequest(SERVER_DEPLOYMENTS_API, {
      requestId: rowRequestId,
      deploymentType
    }).then(data => {
      // console.log(data.ResponseMetadata);
      console.log(data);
      setApiFetching(false);
      setApiResponse(
        <ul>
          {data.map(info => (
            <li>{info.message}</li>
          ))}
        </ul>
      );
    });
  };

  const handleRejectConfirmation = () => {
    setApiFetching(true);
    setShowApiPopup(true);
    deleteRequest(SERVER_DEPLOYMENTS_API, {
      requestId: rowRequestId
    }).then(data => {
      console.log(data.ResponseMetadata);
      setApiFetching(false);
      if (data.ResponseMetadata.HTTPStatusCode === 200) {
        setApiResponse("Succesfully deleted deployment request");
      } else {
        setApiResponse("Failed to delete deployment request. PLease try again");
      }
    });
    // console.log(rowRequestId);
  };

  const handleApproveAction = (requestId, depType) => {
    setRowRequestId(requestId);
    setDeploymentType(depType);
    handleApproveConfirmationDialogOpen();
  };

  const handleRejectAction = requestId => {
    setRowRequestId(requestId);
    handleRejectConfirmationDialogOpen();
  };

  return (
    <div>
      <p>This page contains unapproved Server Deployments Requests</p>
      {isLoading && (
        <div>
          <LoadingDialog message="Unapproved Server Deployments Requests Data Loading..." />
          <LinearProgress />
        </div>
      )}
      {error && (
        <div>
          <ErrorDialog
            link="/server-deployments/admin-approval"
            title="Server Deployments Request Approval Page"
          />
        </div>
      )}
      {rawData && (
        <CreateDeploymentsApprovalTable
          data={rawData}
          handleApproveAction={handleApproveAction}
          handleRejectAction={handleRejectAction}
        />
      )}
      <DumbConfirmationDialog
        open={openApproveConfirmationDialog}
        onClose={handleApproveConfirmationDialogClose}
        title="Approve Deployments Request"
        question="approve this deployment request"
        onConfirm={handleApproveConfirmation}
      />
      <DumbConfirmationDialog
        open={openRejectConfirmationDialog}
        onClose={handleRejectConfirmationDialogClose}
        title="Reject Deployments Request"
        question="Reject and delete this deployment request"
        onConfirm={handleRejectConfirmation}
      />
      {showApiPopup && (
        <ApiPopupDialog
          open={showApiPopup}
          onClose={() => {
            setShowApiPopup(false);
            setRawData(null);
            setIsLoading(true);
          }}
          apiFetching={apiFetching}
          completeMessage={apiResponse}
        />
      )}
    </div>
  );
};

export default ServerDeploymentsApprovalPage;
