import React from "react";
import { LinearProgress } from "@material-ui/core";
import { useSubheader } from "../../_metronic/layout";
import ReleaseApprovalTable from "../custom_ui_components/ReleasesApprovalTable";
import useFetch from "../custom_hooks/useFetch";
import {
  GITHUB_RELEASE_GET_UNAPRROVED_RELEASES_API,
  GITHUB_RELEASE_APPROVE_REQUEST_API,
  GITHUB_RELEASE_DELETE_REQUEST_API
} from "../../env";

import LoadingDialog from "../custom_ui_components/LoadingDialog";
import ErrorDialog from "../custom_ui_components/ErrorDialog";
import useAuthCognitoGroup from "../custom_hooks/useAuthCognitoGroup";

const GithubReleaseApprovalPage = () => {
  // determines user permission based on cognito group
  const isSuperadmin = useAuthCognitoGroup("superadmin");
  const permissionAllowed = isSuperadmin;

  const suhbeader = useSubheader();
  suhbeader.setTitle("Github Release Approval Page");

  // GET unapproved releases
  const { rawData, isLoading, error, setIsLoading, setRawData} = useFetch(
    GITHUB_RELEASE_GET_UNAPRROVED_RELEASES_API
  );

  // PUT: approve request, takes in requestValues object which contains repo and tag version
  async function approveRequest(requestValues) {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestValues)
    };
    const response = await fetch(
      GITHUB_RELEASE_APPROVE_REQUEST_API,
      requestOptions
    );
    const data = await response.json();
    return data;
  }

  // DELETE: delete/reject a created request and remove from db
  async function deleteRequest(requestValues) {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestValues)
    };
    const response = await fetch(
      GITHUB_RELEASE_DELETE_REQUEST_API,
      requestOptions
    );
    const data = await response.json();
    return data;
  }

  return (
    <div>
      <p>This page contains unapproved Github Releases</p>
      {isLoading && (
        <div>
          <LoadingDialog message="Unapproved Github Requests Data Loading..." />
          <LinearProgress />
        </div>
      )}
      {error && (
        <div>
          <ErrorDialog
            link="/github-release-page/admin-approval"
            title="Github Release Approval Page"
          />
        </div>
      )}
      {rawData && (
        <ReleaseApprovalTable
          data={rawData}
          handleApprove={approveRequest}
          handleReject={deleteRequest}
          hasPermission={permissionAllowed}
          setIsLoading={setIsLoading}
          setRawData={setRawData}
        />
      )}
    </div>
  );
};

export default GithubReleaseApprovalPage;
