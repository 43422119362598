import React, { useState } from "react";
import MaterialTable from "material-table";

const UserManagementTable = props => {
  // const [refresh, setRefresh] = useState(props.shouldRefresh);
  return (
    <div>
      <MaterialTable
        columns={[
          { title: "No.", field: "number" },
          { title: "Username", field: "username" },
          { title: "Role", field: "role" },
          { title: "User Status", field: "userStatus" },
          { title: "Enabled", field: "enabled" },
          { title: "Email Address", field: "email" }
        ]}
        data={props.data}
        title="User Management"
        actions={[
          {
            icon: "edit",
            tooltip: "Edit User Information",
            onClick: (event, rowData) => {
              props.handleEditAction(
                rowData.username,
                rowData.role,
                rowData.email
              );
            }
          },
          {
            icon: "delete",
            tooltip: "Delete User",
            onClick: (event, rowData) => {
              props.handleDeleteAction(rowData.username);
            }
          }
        ]}
        options={{
          actionsColumnIndex: -1
        }}
      />
    </div>
  );
};

export default UserManagementTable;
