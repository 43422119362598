import {
  COGNITO_CREATE_USER_API,
  COGNITO_DELETE_USER_API,
  COGNITO_EDIT_USER_API
} from "../../env";

async function createUser(username, email, password, role) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, email, password, role })
  };
  const response = await fetch(COGNITO_CREATE_USER_API, requestOptions);
  const data = await response.json();
  return data;
}

// required: username
async function editUser(username, email, password, oldRole, newRole) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, email, password, oldRole, newRole })
  };
  const response = await fetch(COGNITO_EDIT_USER_API, requestOptions);
  const data = await response.json();
  return data;
}

async function deleteUser(username) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username })
  };
  const response = await fetch(COGNITO_DELETE_USER_API, requestOptions);
  const data = await response.json();
  return data;
}

export { createUser, editUser, deleteUser };
