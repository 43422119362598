import React from "react";
import { useParams } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";

import { useSubheader } from "../../_metronic/layout";
import useFetch from "../custom_hooks/useFetch";
import { GITHUB_RELEASE_GET_RELEASES_OF_A_REPO_API } from "../../env";

import LoadingDialog from "../custom_ui_components/LoadingDialog";
import ErrorDialog from "../custom_ui_components/ErrorDialog";
import GithubRepoReleasesTable from "../custom_ui_components/GithubRepoReleasesTable";

import { formatGithubReleasesIndividualRepoData } from "../custom_helpers/tableDataFilters";

const GithubReleasesIndRepoPage = () => {
  const { repo } = useParams();
  const suhbeader = useSubheader();
  suhbeader.setTitle(`History of Releases of: ${repo}`);
  // const { rawData, isLoading, error } = useFetch(GITHUB_RELEASE_GET_API + repo);
  const { formattedData, isLoading, error } = useFetch(
    GITHUB_RELEASE_GET_RELEASES_OF_A_REPO_API + repo,
    formatGithubReleasesIndividualRepoData
  );
  // const releases = await rawData.Items;
  // console.log(releases);
  console.log(formattedData);

  // this page will useFetch the dynamoDB API by passing in the repo variable.
  return (
    <div>
      <p>This page shows Github Releases of {repo}</p>
      {isLoading && (
        <div>
          <LoadingDialog message="Github Release data loading..." />
          <LinearProgress />
        </div>
      )}
      {error && (
        <div>
          <ErrorDialog
            link={`/github-release-page/${repo}`}
            title={`Repository: ${repo}`}
          />
        </div>
      )}
      {formattedData && <GithubRepoReleasesTable data={formattedData} />}
    </div>
  );
};

export default GithubReleasesIndRepoPage;
