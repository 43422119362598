/* eslint-disable no-console */
import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select
} from "@material-ui/core";

import LargeButton from "./LargeButton";
import PermissionDeniedDialog from "./permissionDeniedDialog";
import IsEmptyDialog from "./IsEmptyDialog";
import { MQTT_PUBLISH_TO_TOPIC_API } from "../../env";
import ApiPopupDialog from "./ApiPopupDialog";

export default function PublishConfigConfirmationDialog({ selected }) {
  // gets cognito groups from redux store to determine if user can access certain feature
  const cognitoGroups = useSelector(
    state => state.auth.user["cognito:groups"],
    shallowEqual
  );

  // to open publish confirmation dialog or not
  const [open, setOpen] = useState(false);

  // to determine if empty group array, which is used to determine dialog message
  const [isEmpty, setIsEmpty] = useState(false);

  // determine if server or camera config
  const [configType, setConfigType] = useState("");

  // returns boolean based on user's role
  const allowPermissionToPublish = () => {
    if (cognitoGroups.includes("superadmin")) return true;
    if (cognitoGroups.includes("normal-user")) return true;
    return false;
  };

  // sets permission depending on user's role; js hoisting thus can use function declared after
  const [permissionAllowed, setPermissionAllowed] = useState(
    allowPermissionToPublish()
  );

  // api fetch dialog useState hook
  const [showApiPopup, setShowApiPopup] = useState(false);
  const [apiFetching, setApiFetching] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [branchList, setBranchList] = useState([]);

  // function to in api dialog

  const handleClickOpen = () => {
    // only pops up if selected an array
    if (selected.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
      setOpen(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      branch: ""
    }
  });

  async function getBranch(config) {
    setApiFetching(true);
    setShowApiPopup(true);

    const selectedGroups = selected.map(group => ({ name: group.name }));
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        groups: selectedGroups,
        configType: config,
        branch: formik.values.branch,
        branchFlag: true
      })
      // returns a json object containing 3 keys: groups, configType and branch, and branchFlag boolean
    };
    fetch(MQTT_PUBLISH_TO_TOPIC_API, requestOptions)
      .then(response => response.json())
      .then(data => {
        setShowApiPopup(false);
        setApiFetching(false);
        setApiResponse(JSON.stringify(data, null, "\t"));
        setBranchList(data);
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  // publish either server-specific or camera config to selected servers
  async function publishConfig(config) {
    setApiFetching(true);
    setShowApiPopup(true);
    // converts array of group string into array of group object
    const selectedGroups = selected.map(group => ({ name: group.name }));
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        groups: selectedGroups,
        configType: config,
        branch: formik.values.branch,
        branchFlag: false
      })
      // returns a json object containing 3 keys: groups, configType and branch, and branchFlag boolean
    };

    fetch(MQTT_PUBLISH_TO_TOPIC_API, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);

        setApiFetching(false);
        setApiResponse(JSON.stringify(data, null, "\t"));
      });
    console.log(JSON.stringify({ groups: selectedGroups, configType: config }));
  }

  PublishConfigConfirmationDialog.propTypes = {
    selected: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  return (
    <div>
      <LargeButton
        title="Publish Server Config"
        variant="contained"
        onClick={() => {
          handleClickOpen();
          setConfigType("server");
          getBranch(configType);
        }}
        disabled={selected.length === 0}
      />
      <br />
      <LargeButton
        title="Publish Camera Config"
        variant="contained"
        onClick={() => {
          handleClickOpen();
          setConfigType("camera");
        }}
        disabled={selected.length === 0}
      />

      {/* Dialog Component that makes API call if user selects confirm  */}
      {permissionAllowed && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Publish Config to MQTT Topic(s)
          </DialogTitle>

          <DialogContent>
            <p>Please choose a branch:</p>
            <Select
              native
              name="branch"
              value={formik.values.branch} // get from branches_list array
              onChange={formik.handleChange}
            >
              <option aria-label="None" value="" />
              {branchList.map(
                (
                  branch // change array to branches_list array returned by getBranch()
                ) => (
                  <option value={branch}>{branch}</option>
                ) // all element in array will be display as option
              )}
            </Select>
            {/* <button type="submit">Submit</button> to trigger onSubmit function */}

            <DialogContentText id="alert-dialog-description">
              <p>
                Are you sure you want to publish {configType} config to the
                following {selected.length} Greengrass server(s)?
              </p>
            </DialogContentText>
            {/* List of groups that are selected */}
            <ul>
              {selected.map(group => (
                <li key={group.name}>{group.name}</li>
              ))}
            </ul>
          </DialogContent>
          <DialogActions>
            <LargeButton
              title="No, Cancel the Action"
              variant="outlined"
              onClick={handleClose}
            />
            <LargeButton
              title="Yes, Proceed"
              variant="outlined"
              onClick={() => {
                handleClose();
                publishConfig(configType);
              }}
              disabled={formik.values.branch === ""} // disable button when no value chosen
            />
          </DialogActions>
        </Dialog>
      )}

      {/* displayed instead if permission not granted to user's role */}
      {!permissionAllowed && (
        <PermissionDeniedDialog
          open={open}
          onClose={() => {
            handleClose();
          }}
        />
      )}

      {/* displayed  if no groups selected */}
      {isEmpty && (
        <IsEmptyDialog
          open={open}
          onClose={() => {
            handleClose();
          }}
        />
      )}

      {/* show api progress and response upon completion */}
      {showApiPopup && (
        <ApiPopupDialog
          open={showApiPopup}
          onClose={() => {
            setShowApiPopup(false);
          }}
          apiFetching={apiFetching}
          completeMessage={apiResponse}
        />
      )}
    </div>
  );
}
