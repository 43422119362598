import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//this dialog takes in props to handleClickOpen
export default function PermissionDeniedDialog(props) {
  return (
    <div>

      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Unauthorized Access</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Error: You are not authorized to perform this action. 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Close Dialog
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}
