import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";

// self-created page(s)
import GreengrassPage from "./pages/GreengrassPage";
import GreengrassHistoryPage from "./pages/GreengrassHistoryPage";
import GithubReleasePage from "./pages/GithubReleasePage";
import GithubReleasesIndRepoPage from "./pages/GithubReleasesIndRepoPage";
import GithubReleaseApprovalPage from "./pages/GithubReleaseApprovalPage";
import UserManagementPage from "./pages/UserManagementPage";
import ServerDeploymentsApprovalPage from "./pages/ServerDeploymentsApprovalPage";
import LatestModelPathsPage from "./pages/LatestModelPathsPage";
import ModelCopyingApprovalPage from "./pages/ModelCopyingApprovalPage";

// import custom auth hook
import useAuthCognitoGroup from "./custom_hooks/useAuthCognitoGroup";

export default function BasePage() {
  // boolean to check wheter logged in user is superadmin;
  const isSuperadmin = useAuthCognitoGroup("superadmin");
  const isNormalUser = useAuthCognitoGroup("normal-user");

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute
          exact
          path="/greengrass-page"
          component={GreengrassPage}
        />
        <ContentRoute
          exact
          path="/greengrass-page/:id"
          component={GreengrassHistoryPage}
        />
        <ContentRoute
          exact
          path="/github-release-page"
          component={GithubReleasePage}
        />
        {/* only superadmin can access github release approval page */}
        {isSuperadmin && (
          <ContentRoute
            exact
            path="/github-release-page/admin-approval"
            component={GithubReleaseApprovalPage}
          />
        )}
        <ContentRoute
          exact
          path="/github-release-page/:repo/releases-history"
          component={GithubReleasesIndRepoPage}
        />
        {/* only superadmin can access user management page */}
        {isSuperadmin && (
          <ContentRoute
            exact
            path="/user-management"
            component={UserManagementPage}
          />
        )}

        {/* only devs and superadmin can access server deployments approval page */}
        {(isSuperadmin || isNormalUser) && (
          <ContentRoute
            exact
            path="/server-deployments/admin-approval"
            component={ServerDeploymentsApprovalPage}
          />
        )}
        {(isSuperadmin || isNormalUser) && (
          <ContentRoute
            exact
            path="/model-update/admin-approval"
            component={ModelCopyingApprovalPage}
          />
        )}
        <ContentRoute
          exact
          path="/latest-model-paths"
          component={LatestModelPathsPage}
        />

        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
