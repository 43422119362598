import React from "react";
import { LinearProgress } from "@material-ui/core";
import { useSubheader } from "../../_metronic/layout";
import useFetch from "../custom_hooks/useFetch";
import LoadingDialog from "../custom_ui_components/LoadingDialog";
import ErrorDialog from "../custom_ui_components/ErrorDialog";
import { LATEST_MODEL_PATHS_API } from "../../env";
import LatestModelPathsTable from "../server_deployments_/LatestModelPathsTable";

const LatestModelPathsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Latest Model Paths");

  const { rawData, isLoading, error } = useFetch(LATEST_MODEL_PATHS_API);

  return (
    <div>
      <p>This page show the latest model paths of each Greengrass groups</p>
      {isLoading && (
        <div>
          <LoadingDialog message="Latest Model Paths loading..." />
          <LinearProgress />
        </div>
      )}
      {error && (
        <div>
          <ErrorDialog
            link="/latest-model-paths"
            title="Latest Model Paths For Greengrass Groups"
          />
        </div>
      )}
      {rawData && <LatestModelPathsTable data={rawData} />}
    </div>
  );
};

export default LatestModelPathsPage;
