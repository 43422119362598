import React, { useState } from "react";
import { Link, Redirect, Switch, Route } from "react-router-dom";
import { Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import * as auth from "../_redux/authRedux"; // imports including action creators
// import { login } from "../_redux/authCrud";

// login packages needed
import UserPool from "../../../custom_cognitoAuth/UserPool";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: ""
};

// Login functional component
function Login(props) {
  // log to see what props actually is
  console.log("props are:", props);

  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  //= ===========metronic's formik code starts===============
  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: LoginSchema,
  //   onSubmit: (values, { setStatus, setSubmitting }) => {
  //     enableLoading();
  //     setTimeout(() => {
  //       login(values.email, values.password) // it is a function that submits a post request, imported from authCrud.js
  //         .then(({ data: { accessToken } }) => { //destructures accessToken from data? I think so
  //           // post request returns data, do something with it
  //           //console.log to know what is data
  //           console.log('from Login.js: ', 'data is: ', accessToken)
  //           disableLoading();
  //           props.login(accessToken); // what is props here? use the acccess token from response to login?
  //           //console log to know what's happening
  //           console.log('from Login.js: ', 'prop is: ', props)
  //         })
  //         .catch(() => {
  //           disableLoading();
  //           setSubmitting(false);
  //           setStatus(
  //             intl.formatMessage({
  //               id: "AUTH.VALIDATION.INVALID_LOGIN"
  //             })
  //           );
  //         });
  //     }, 1000);
  //   }
  // });
  //= ==========metronic's formik code ends===========

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      const user = new CognitoUser({
        Username: values.email,
        Pool: UserPool
      });

      const authDetails = new AuthenticationDetails({
        Username: values.email,
        Password: values.password
      });

      user.authenticateUser(authDetails, {
        onSuccess: data => {
          setLoading(false);
          console.log("onSuccess: ", data);

          const payload = data.getAccessToken().decodePayload();
          console.log(payload);
          props.cognitoLogin(payload);
          // redirects to BasePage
          // eslint-disable-next-line no-unused-expressions
          <Redirect to="/dashboard" />;
        },
        onFailure: err => {
          setLoading(false);
          setSubmitting(false);
          console.error("onFailure: ", err);
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN"
            })
          );
        },
        newPasswordRequired: data => {
          setSubmitting(false);
          setLoading(false);
          console.log("newPasswordRequired: ", data);
        }
      });
    }
  });
  // self  implementation of formik ends

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/* begin::Form */}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            <div className="alert-text ">
              Use your Cognito email/username and password to continue.
            </div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email or Username"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3"
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white" />}
          </button>
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
}

// null because there is no redux store' state to match to props? yes, pretty much
export default injectIntl(connect(null, auth.actions)(Login));
// first param is mapStateToProps, second param is mapDispatchToProps
// the param names (mapStateToProps, mapDispatchToProps)
// are not specified here because they are just conventions,not necessarily
// have to be the same.

// second param: 'auth.actions' is the actionCreators from authRedux.js.
// But why NOT a store.dispatch action?
// because the props in the formik is doing the dispatch action, thus
// the props contains the action creators (accessed using props.dispatch() )
