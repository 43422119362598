import React, { useState } from "react";
import { LinearProgress } from "@material-ui/core";
import { useSubheader } from "../../_metronic/layout";
import useFetch from "../custom_hooks/useFetch";
import LoadingDialog from "../custom_ui_components/LoadingDialog";
import ErrorDialog from "../custom_ui_components/ErrorDialog";
import useAuthCognitoGroup from "../custom_hooks/useAuthCognitoGroup";
import DumbConfirmationDialog from "../custom_ui_components/DumbConfirmationDialog";
import ApiPopupDialog from "../custom_ui_components/ApiPopupDialog";
import { MODEL_UPDATE_IN_S3_API } from "../server_deployments_/helpers/serverDeploymentsAPI";
import {
  deleteRequest,
  approveRequest
} from "../server_deployments_/helpers/serverApiHelpers";
import ModelUpdateApprovalTable from "../server_deployments_/ModelUpdateApprovalTable";

const ModelCopyingApprovalPage = () => {
  // determines user permission based on cognito group
  const isSuperadmin = useAuthCognitoGroup("superadmin");
  const permissionAllowed = isSuperadmin;

  const suhbeader = useSubheader();
  suhbeader.setTitle("Model Copying Request Approval Page");

  // GET unapproved releases
  const { rawData, isLoading, error, setIsLoading, setRawData } = useFetch(
    MODEL_UPDATE_IN_S3_API
  );

  // show confirmation dialog or not
  const [
    openApproveConfirmationDialog,
    setApproveConfirmationDialogOpen
  ] = useState(false);
  const [
    openRejectConfirmationDialog,
    setRejectConfirmationDialogOpen
  ] = useState(false);

  // api fetch dialog useState hook
  const [showApiPopup, setShowApiPopup] = useState(false);
  const [apiFetching, setApiFetching] = useState(false);
  const [apiResponse, setApiResponse] = useState("");

  // store row's requestId
  const [rowRequestId, setRowRequestId] = useState("");

  // handles showing "approve" and "reject" confirmation dialog for
  const handleApproveConfirmationDialogOpen = () => {
    setApproveConfirmationDialogOpen(true);
  };

  const handleApproveConfirmationDialogClose = () => {
    setApproveConfirmationDialogOpen(false);
  };

  const handleRejectConfirmationDialogOpen = () => {
    setRejectConfirmationDialogOpen(true);
  };

  const handleRejectConfirmationDialogClose = () => {
    setRejectConfirmationDialogOpen(false);
  };

  // the below 2 methods that actually calls the API endpoints
  const handleApproveConfirmation = () => {
    setApiFetching(true);
    setShowApiPopup(true);
    approveRequest(MODEL_UPDATE_IN_S3_API, { requestId: rowRequestId }).then(
      data => {
        // console.log(data.ResponseMetadata);
        console.log(data);
        setApiFetching(false);
        if (
          data.ResponseMetadata !== undefined &&
          data.ResponseMetadata.HTTPStatusCode === 200
        ) {
          setApiResponse("Model Copy Request Approved. Copy is successful");
        } else {
          setApiResponse(data.errorMessage);
        }
      }
    );
  };

  const handleRejectConfirmation = () => {
    setApiFetching(true);
    setShowApiPopup(true);
    deleteRequest(MODEL_UPDATE_IN_S3_API, {
      requestId: rowRequestId
    }).then(data => {
      console.log(data.ResponseMetadata);
      setApiFetching(false);
      if (
        data.ResponseMetadata !== undefined &&
        data.ResponseMetadata.HTTPStatusCode === 200
      ) {
        setApiResponse("Succesfully deleted model copy request");
      } else {
        setApiResponse("Failed to delete model copy request. PLease try again");
      }
    });
  };

  const handleApproveAction = requestId => {
    setRowRequestId(requestId);
    handleApproveConfirmationDialogOpen();
  };

  const handleRejectAction = requestId => {
    setRowRequestId(requestId);
    handleRejectConfirmationDialogOpen();
  };

  return (
    <div>
      <p>This page contains unapproved Model Copying Requests</p>
      {isLoading && (
        <div>
          <LoadingDialog message="Unapproved Model Copy Requests Data Loading..." />
          <LinearProgress />
        </div>
      )}
      {error && (
        <div>
          <ErrorDialog
            link="/model-update/admin-approval"
            title="Model Copying Request Approval Page"
          />
        </div>
      )}
      {rawData && (
        <ModelUpdateApprovalTable
          data={rawData}
          handleApproveAction={handleApproveAction}
          handleRejectAction={handleRejectAction}
          permissionAllowed={permissionAllowed}
        />
      )}
      <DumbConfirmationDialog
        open={openApproveConfirmationDialog}
        onClose={handleApproveConfirmationDialogClose}
        title="Approve Model copy Request"
        question="approve this model copy request"
        onConfirm={handleApproveConfirmation}
      />
      <DumbConfirmationDialog
        open={openRejectConfirmationDialog}
        onClose={handleRejectConfirmationDialogClose}
        title="Reject Model Copy Request"
        question="Reject and delete this model copy request"
        onConfirm={handleRejectConfirmation}
      />
      {showApiPopup && (
        <ApiPopupDialog
          open={showApiPopup}
          onClose={() => {
            setShowApiPopup(false);
            setRawData(null);
            setIsLoading(true);
          }}
          apiFetching={apiFetching}
          completeMessage={apiResponse}
        />
      )}
    </div>
  );
};

export default ModelCopyingApprovalPage;
