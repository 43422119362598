import React from "react";
import { Paper, CircularProgress, Typography } from "@material-ui/core";

const LoadingDialog = props => {
  // add some themes here

  const container = {
    height: "100%",
    width: "100%",
    textAlign: "center",
    rounded: true,
    paddingTop: 65,
    paddingBottom: 65,
    backgroundColor: "white",
    opacity: 0.8,
    margin: "auto"
  };

  return (
    <Paper style={container}>
      <Typography variant="h3">{props.message}</Typography>
      <br />
      <CircularProgress />
    </Paper>
  );
};
export default LoadingDialog;
