import React from "react";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";

// dialog to prompt user about publishing config to server(s)

const ServersTable = props => (
  <div>
    <MaterialTable
      title="Server Deployments"
      columns={[
        {
          title: "Group Name",
          field: "name",
          render: row => (
            <Link to={`greengrass-page/${row.groupId}`}>{row.name}</Link>
          )
        },
        { title: "Group ID", field: "groupId" },
        { title: "Last Deployed Date & Time", field: "deployed" },
        { title: "Latest Version", field: "version" }
      ]}
      data={props.data}
      options={{
        selection: true,
        padding: "dense",
        pageSize: 25,
        pageSizeOptions: [10, 25, 50]
      }}
      onSelectionChange={rows => {
        // setSelected(rows);
        const filteredRows = rows.map(group => ({
          name: group.name,
          groupId: group.groupId,
          groupLatestVersionId: group.version
        }));
        props.getSelectedGroups(filteredRows);
      }}
    />
  </div>
);

export default ServersTable;
