// this js will store commonly used variables, such as api endpoints:

const GREENGRASS_INFO_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/greengrass-info";

// Individual group history API
const GREENGRASS_GROUP_HISTORY_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/group-history/?groupid=";

// Publish Config API
const MQTT_PUBLISH_TO_TOPIC_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/mqtt-topic";

// 2. Github Release Lambda APIs
// GET releases overview
const GITHUB_RELEASE_GET_OVERVIEW_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/-github-release/releases-overview";

// GET releases of individual repo
const GITHUB_RELEASE_GET_RELEASES_OF_A_REPO_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/-github-release/";

// GET unapproved releases for superadmin
const GITHUB_RELEASE_GET_UNAPRROVED_RELEASES_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/-github-release/unapproved-releases";

// POST: create a release request
const GITHUB_RELEASE_ADD_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/-github-release/request";

// PUT: approve a release request (only superadmin)
const GITHUB_RELEASE_APPROVE_REQUEST_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/-github-release/request-approval";

// DELETE: delete a created request (either superadmin or devs). same as "approve request" endpoint
// but different method
const GITHUB_RELEASE_DELETE_REQUEST_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/-github-release/request-approval";

// all 4 apis have same endpoint
const COGNITO_CREATE_USER_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/cognito-admin";
const COGNITO_EDIT_USER_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/cognito-admin";
const COGNITO_LIST_USERS_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/cognito-admin";
const COGNITO_DELETE_USER_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/cognito-admin";

// apis for server deployments, model deployment and model updating
const SERVER_DEPLOYMENTS_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/server-deployment";

const MODEL_UPDATE_IN_S3_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/model-copy";

const LATEST_MODEL_PATHS_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/model-deployment";

export {
  GREENGRASS_INFO_API,
  GREENGRASS_GROUP_HISTORY_API,
  MQTT_PUBLISH_TO_TOPIC_API,
  GITHUB_RELEASE_GET_OVERVIEW_API,
  GITHUB_RELEASE_GET_RELEASES_OF_A_REPO_API,
  GITHUB_RELEASE_GET_UNAPRROVED_RELEASES_API,
  GITHUB_RELEASE_ADD_API,
  GITHUB_RELEASE_APPROVE_REQUEST_API,
  GITHUB_RELEASE_DELETE_REQUEST_API,
  COGNITO_CREATE_USER_API,
  COGNITO_EDIT_USER_API,
  COGNITO_LIST_USERS_API,
  COGNITO_DELETE_USER_API,
  SERVER_DEPLOYMENTS_API,
  MODEL_UPDATE_IN_S3_API,
  LATEST_MODEL_PATHS_API
};
