const CAMERA_INFERENCE = {
  repository: "terraform-aws-lambda-camera_inference",
  description: "Terraform module for camera_inference lambda"
};

const DATA_UPLOADER = {
  repository: "terraform-aws-lambda-data_uploader",
  description: "Terraform module for data_uploader lambda"
};

const METRICS_PUBLISHER = {
  repository: "terraform-aws-lambda-metrics_publisher",
  description: "Terraform metrics_publisher lambda module"
};

const VIEWER = {
  repository: "terraform-aws-lambda-viewer",
  description: "Terraform module for AWS Greengrass viewer lambda"
};

const CONFIGURER = {
  repository: "terraform-aws-lambda-configurer",
  description: "Terraform module for configurer lambda"
};

export {
  CAMERA_INFERENCE,
  DATA_UPLOADER,
  METRICS_PUBLISHER,
  VIEWER,
  CONFIGURER
};
