import { useState, useEffect } from "react";

// custom hook to return filtered data
const useFetch = (url, formatCallback) => {
  const [rawData, setRawData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formattedData, setFormattedData] = useState(null);

  useEffect(() => {
    fetch(url)
      .then(res => {
        if (!res.ok) {
          // if server returns an error message
          throw Error("could not fetch the data from the API endpoints");
        }
        return res.json();
      })
      .then(json => {
        setIsLoading(false);
        setRawData(json);
        setError(null);
        // only format data if callback is provided
        if (typeof formatCallback === "function")
          setFormattedData(formatCallback(json));
      })
      .catch(err => {
        // auto catches network / connection error
        setIsLoading(false);
        setError(err.message);
      });
  }, [formatCallback, url, isLoading]); // url as dependency, so whenever url changes the useEffect will re-run

  return {
    rawData,
    formattedData,
    isLoading,
    error,
    setRawData,
    setFormattedData,
    setIsLoading
  };
};

export default useFetch;
