import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import GithubRequestFormDialog from "./GithubRequestFormDialog";
import DumbConfirmationDialog from "./DumbConfirmationDialog";

const useStyles = makeStyles({
  root: {
    minWidth: 320,
    maxWidth: 380,
    marginBottom: 20,
    marginRight: 30,
    display: "inline-block"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  action: {
    justifyContent: "space-around"
  }
});

export default function SimpleCard(props) {
  const classes = useStyles();

  // hooks for openDialog (dumb confirmation dialog)
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Repository: {props.title}
        </Typography>
        <Typography variant="h5" component="h2" />
        <Typography className={classes.pos} color="textSecondary" />
        <Typography variant="body2" component="p">
          Description:
          {props.description}
        </Typography>
        <Typography variant="body2" component="p">
          <br />
          Latest Release Version: {props.releaseVersion}
          <br />
          Title:{props.releaseTitle}
          <br />
          Approval Status: {props.releaseApproved}
        </Typography>
      </CardContent>
      <CardActions className={classes.action}>
        <NavLink to={`github-release-page/${props.title}/releases-history`}>
          History of Releases
        </NavLink>
        {/* DeleteRequest Button */}
        {/* shows "delete request" button" if there is pending requests */}
        {props.releaseApproved === "Pending" && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpenDialog(true); // set dialog'state to true
            }}
          >
            Delete Release Request
          </Button>
        )}
        {/* DeleteRequest Dialog */}
        {/* shows confirmation dialog onClick of "delete request" button */}
        {openDialog && (
          <DumbConfirmationDialog
            open={openDialog}
            onClose={() => {
              setOpenDialog(false);
            }}
            title="Delete Github Release Request"
            question="delete this request from dynamoDB"
            onConfirm={
              // calls the deleteRequest() async method here
              () => {
                props.onDeleteRequest();
              }
            }
          />
        )}
        {/* GithubRequestFormDialog */}
        {/* shows "create request" button if no pending requests */}
        {props.releaseApproved !== "Pending" && (
          <GithubRequestFormDialog title={props.title} />
        )}
      </CardActions>
    </Card>
  );
}
