const SERVER_DEPLOYMENTS_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/server-deployment";

const MODEL_UPDATE_IN_S3_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/model-copy";

const LATEST_MODEL_PATHS_API =
  "https://nwgp7bowjb.execute-api.ap-southeast-1.amazonaws.com/beta/model-deployment";

export {
  SERVER_DEPLOYMENTS_API,
  MODEL_UPDATE_IN_S3_API,
  LATEST_MODEL_PATHS_API
};
