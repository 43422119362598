import { Button } from "@material-ui/core";
import React from "react";

const LargeButton = ({ title, onClick, variant, disabled }) => (
  <div>
    <Button
      color="primary"
      size="large"
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      // style = {{
      //   position: 'absolute',
      //   right: 0
      // }}
    >
      {title}
    </Button>
  </div>
);
export default LargeButton;
