import { useSelector, shallowEqual } from "react-redux";

// this function gets cognito groups from redux store to determine if user can access certain feature
export default function useAuthCognitoGroup(groupWithPermission) {
  // array of cognito groups that the current user is assigned to
  const cognitoGroups = useSelector(
    state => state.auth.user["cognito:groups"],
    shallowEqual
  );

  if (cognitoGroups === undefined) return false;

  if (cognitoGroups.includes(groupWithPermission)) {
    return true;
  }

  return false;
}
