import React, { useState } from "react";
import { LinearProgress } from "@material-ui/core";
import { useSubheader } from "../../_metronic/layout";
import LoadingDialog from "../custom_ui_components/LoadingDialog";
import ErrorDialog from "../custom_ui_components/ErrorDialog";
import UserManagementTable from "../custom_ui_components/UserManagementTable";
import UserManagementFormDialog from "../custom_ui_components/UserManagementFormDialog";
import DumbConfirmationDialog from "../custom_ui_components/DumbConfirmationDialog";

import useFetch from "../custom_hooks/useFetch";
import LargeButton from "../custom_ui_components/LargeButton";
import { COGNITO_LIST_USERS_API } from "../../env";
import { formatCognitoUsersData } from "../custom_helpers/tableDataFilters";
import {
  createUser,
  deleteUser,
  editUser
} from "../custom_cognitoAuth/cognitoLambdaHelpers";
import ApiPopupDialog from "../custom_ui_components/ApiPopupDialog";

const UserManagementPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("User Management Page");

  const {
    formattedData,
    isLoading,
    error,
    setIsLoading,
    setFormattedData
  } = useFetch(COGNITO_LIST_USERS_API, formatCognitoUsersData);

  console.log(formattedData);

  // show user management dialog or not
  const [open, setDialogOpen] = useState(false);

  // set to true if "edit" button clicked
  const [isEdit, setIsEdit] = useState(false);

  // set to true if "create user" clicked
  const [isCreate, setIsCreate] = useState(false);

  // show confirmation dialog or not
  const [openConfirmationDialog, setConfirmationDialogOpen] = useState(false);

  // sets managed user's state
  const [username, setUsername] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userEmail, setUserEmail] = useState("");

  // api fetch dialog useState hook
  const [showApiPopup, setShowApiPopup] = useState(false);
  const [apiFetching, setApiFetching] = useState(false);
  const [apiResponse, setApiResponse] = useState("");

  // handles showing User Management form dialog
  const handleDialogOpen = () => {
    setDialogOpen(true);
    console.log("opened");
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setIsEdit(false);
    setIsCreate(false);
  };

  // handles showing confirmation dialog
  const handleConfirmationDialogOpen = () => {
    setConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleDeleteConfirmation = () => {
    setApiFetching(true);
    setShowApiPopup(true);
    deleteUser(username).then(data => {
      console.log(data);
      setApiFetching(false);
      setApiResponse(data.message);
    });
  };

  // handles actions for the table (passed down as props)
  const handleEditAction = (userName, role, email) => {
    setIsEdit(true);
    setUsername(userName);
    setUserRole(role);
    setUserEmail(email);
    handleDialogOpen();
  };

  const handleDeleteAction = userName => {
    // do something here
    setUsername(userName);
    handleConfirmationDialogOpen();
  };

  // handle form submission (multile conditionals on update or create?)
  const handleSubmitUserForm = (username, email, password, role) => {
    setApiFetching(true);
    setShowApiPopup(true);
    createUser(username, email, password, role).then(data => {
      console.log(data);
      setApiFetching(false);
      setApiResponse(data.message);
      // setShouldRefresh(true);
    });
  };

  const handleEditUserForm = (username, email, password, oldRole, newRole) => {
    setApiFetching(true);
    setShowApiPopup(true);
    editUser(username, email, password, oldRole, newRole).then(data => {
      console.log(data);
      setApiFetching(false);
      setApiResponse(data.message);
    });
  };

  return (
    <div>
      {isLoading && (
        <div>
          <LoadingDialog message="User Data loading..." />
          <LinearProgress />
        </div>
      )}
      {error && (
        <div>
          <ErrorDialog link="/user-management" title="User Management Page" />
        </div>
      )}
      {formattedData && (
        <div>
          <LargeButton
            title="Create User"
            variant="contained"
            onClick={() => {
              handleDialogOpen();
              setIsCreate(true);
            }}
          />
          <br />
          <UserManagementTable
            handleEditAction={handleEditAction}
            handleDeleteAction={handleDeleteAction}
            handleClose={handleDialogClose}
            data={formattedData}
            // shouldRefresh={shouldRefresh}
          />
          {(isCreate || isEdit) && (
            <UserManagementFormDialog
              open={open}
              handleClose={handleDialogClose}
              // add props to pass user value in case of "Edit Action"
              username={isEdit ? username : ""}
              userRole={isEdit ? userRole : ""}
              userEmail={isEdit ? userEmail : ""}
              isEdit={isEdit}
              isCreate={isCreate}
              handleSubmit={(username, email, password, role) => {
                handleSubmitUserForm(username, email, password, role);
              }}
              handleEdit={(username, email, password, oldRole, newRole) => {
                handleEditUserForm(username, email, password, oldRole, newRole);
              }}
            />
          )}
          <DumbConfirmationDialog
            open={openConfirmationDialog}
            onClose={handleConfirmationDialogClose}
            title="Delete User"
            question="delete this user"
            onConfirm={handleDeleteConfirmation}
            text={username}
          />
          {showApiPopup && (
            <ApiPopupDialog
              open={showApiPopup}
              onClose={() => {
                setShowApiPopup(false);
                setIsLoading(true);
                setFormattedData(null);
              }}
              apiFetching={apiFetching}
              completeMessage={apiResponse}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UserManagementPage;
