import React from "react";
import { LinearProgress } from "@material-ui/core";
import { useSubheader } from "../../_metronic/layout";
import SimpleCard from "../custom_ui_components/SimpleCard";
import useFetch from "../custom_hooks/useFetch";
import {
  GITHUB_RELEASE_GET_OVERVIEW_API,
  GITHUB_RELEASE_DELETE_REQUEST_API
} from "../../env";
import LoadingDialog from "../custom_ui_components/LoadingDialog";
import ErrorDialog from "../custom_ui_components/ErrorDialog";

// vehicleTrack repositories constants
import {
  CAMERA_INFERENCE,
  DATA_UPLOADER,
  METRICS_PUBLISHER,
  VIEWER,
  CONFIGURER
} from "../../constants/vehicleTrackRepos";

const GithubReleasePage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Github Release Page");

  // call api to get latest release for the 5 repos (version & title)?
  const { rawData, isLoading, error, setRawData, setIsLoading } = useFetch(
    GITHUB_RELEASE_GET_OVERVIEW_API
  );
  console.log(rawData);

  // // returns string if latest repo is approved from query
  function latestReleaseIsApproved(approved) {
    if (approved) {
      return "Approved";
    }
    // if not approved then return pending text
    return "Pending";
  }

  // used to delete requests onclick in SimpleCard
  async function deletePendingRequest(repository, tagVersion) {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ repository, tagVersion })
    };
    const response = await fetch(
      GITHUB_RELEASE_DELETE_REQUEST_API,
      requestOptions
    );
    const data = await response.json();

    setRawData(null);
    setIsLoading(true);
    return data;
  }

  return (
    <div>
      <p>
        This page will show the GitHub releases of the 5 VehicleTrack
        repositories
      </p>
      <br />
      {isLoading && (
        <div>
          <LoadingDialog message="Github Release Overview loading..." />
          <LinearProgress />
        </div>
      )}
      {error && (
        <div>
          <ErrorDialog
            link="/github-release-page"
            title="Github Release Page"
          />
        </div>
      )}
      {rawData && (
        <div>
          <SimpleCard
            title={CAMERA_INFERENCE.repository}
            description={CAMERA_INFERENCE.description}
            releaseTitle={rawData[`${CAMERA_INFERENCE.repository}`].title}
            releaseVersion={
              rawData[`${CAMERA_INFERENCE.repository}`].tagVersion
            }
            releaseApproved={latestReleaseIsApproved(
              rawData[`${CAMERA_INFERENCE.repository}`].approved
            )}
            onDeleteRequest={() => {
              deletePendingRequest(
                CAMERA_INFERENCE.repository,
                rawData[`${CAMERA_INFERENCE.repository}`].tagVersion
              );
            }}
          />
          <SimpleCard
            title={DATA_UPLOADER.repository}
            description={DATA_UPLOADER.description}
            releaseTitle={rawData[`${DATA_UPLOADER.repository}`].title}
            releaseVersion={rawData[`${DATA_UPLOADER.repository}`].tagVersion}
            releaseApproved={latestReleaseIsApproved(
              rawData[`${DATA_UPLOADER.repository}`].approved
            )}
            onDeleteRequest={() => {
              deletePendingRequest(
                DATA_UPLOADER.repository,
                rawData[`${DATA_UPLOADER.repository}`].tagVersion
              );
            }}
          />

          <SimpleCard
            title={METRICS_PUBLISHER.repository}
            description={METRICS_PUBLISHER.description}
            releaseTitle={rawData[`${METRICS_PUBLISHER.repository}`].title}
            releaseVersion={
              rawData[`${METRICS_PUBLISHER.repository}`].tagVersion
            }
            releaseApproved={latestReleaseIsApproved(
              rawData[`${METRICS_PUBLISHER.repository}`].approved
            )}
            onDeleteRequest={() => {
              deletePendingRequest(
                METRICS_PUBLISHER.repository,
                rawData[`${METRICS_PUBLISHER.repository}`].tagVersion
              );
            }}
          />
          <SimpleCard
            title={VIEWER.repository}
            description={VIEWER.description}
            releaseTitle={rawData[`${VIEWER.repository}`].title}
            releaseVersion={rawData[`${VIEWER.repository}`].tagVersion}
            releaseApproved={latestReleaseIsApproved(
              rawData[`${VIEWER.repository}`].approved
            )}
            onDeleteRequest={() => {
              deletePendingRequest(
                VIEWER.repository,
                rawData[`${VIEWER.repository}`].tagVersion
              );
            }}
          />
          <SimpleCard
            title={CONFIGURER.repository}
            description={CONFIGURER.description}
            releaseTitle={rawData[`${CONFIGURER.repository}`].title}
            releaseVersion={rawData[`${CONFIGURER.repository}`].tagVersion}
            releaseApproved={latestReleaseIsApproved(
              rawData[`${CONFIGURER.repository}`].approved
            )}
            onDeleteRequest={() => {
              deletePendingRequest(
                CONFIGURER.repository,
                rawData[`${CONFIGURER.repository}`].tagVersion
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default GithubReleasePage;
