import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    float: "right"
  }
});

export default function DeploymentTasksDropdown(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={classes.button}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        disabled={props.disabled}
      >
        Deployment Tasks
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            props.openConfDialog("NewDeployment");
          }}
        >
          New Deployments
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            props.openConfDialog("ResetDeployment");
          }}
        >
          Reset Deployments
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            props.openConfDialog("ForceResetDeployment");
          }}
        >
          Force Reset Deployments
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            props.openModelDeploymentDialog();
          }}
        >
          Model + New Deployments
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            props.openConfDialog("DeleteGroup");
          }}
        >
          Delete Group
        </MenuItem>
      </Menu>
    </div>
  );
}
