/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import useAuthCognitoGroup from "app/custom_hooks/useAuthCognitoGroup";
import { toAbsoluteUrl, checkIsActive } from "_metronic/_helpers";


export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = url =>
    checkIsActive(location, url) ? "menu-item-active" : "";

  const isSuperadmin = useAuthCognitoGroup("superadmin");
  const isNormalUser = useAuthCognitoGroup("normal-user");

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/* begin::Header Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* begin::1 Level */}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/dashboard"
          )}`}
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">Dashboard</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/* end::1 Level */}

        {/* Classic submenu */}
        {/* begin::1 Level */}
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/custom"
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="menu-text">Server Management</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              {/* begin::2 Level */}
              <li
                className={`menu-item ${getMenuItemActive("/greengrass-page")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/greengrass-page">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                    />
                  </span>
                  <span className="menu-text">Greengrass Page</span>
                </NavLink>
              </li>
              {/* end::2 Level */}

              {/* begin::2 Level */}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/latest-model-paths"
                )}`}
                aria-haspopup="true"
              >
                {/* only superadmin and devs are able to view the server deployments approval page link */}
                {(isSuperadmin || isNormalUser) && (
                  <NavLink className="menu-link" to="/latest-model-paths">
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/Layers.svg"
                        )}
                      />
                    </span>
                    <span className="menu-text">Latest Model Paths</span>
                  </NavLink>
                )}
              </li>
              {/* end::2 Level */}

              {/* begin::2 Level */}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/server-deployments/admin-approval"
                )}`}
                aria-haspopup="true"
              >
                {/* only superadmin and devs are able to view the server deployments approval page link */}
                {(isSuperadmin || isNormalUser) && (
                  <NavLink
                    className="menu-link"
                    to="/server-deployments/admin-approval"
                  >
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/Layers.svg"
                        )}
                      />
                    </span>
                    <span className="menu-text">
                      Server Deployments Approval
                    </span>
                  </NavLink>
                )}
              </li>
              {/* end::2 Level */}

              {/* begin::2 Level */}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/model-update/admin-approval"
                )}`}
                aria-haspopup="true"
              >
                {/* only superadmin and devs are able to view the model copying approval page link */}
                {(isSuperadmin || isNormalUser) && (
                  <NavLink
                    className="menu-link"
                    to="/model-update/admin-approval"
                  >
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/Layers.svg"
                        )}
                      />
                    </span>
                    <span className="menu-text">
                      Model Copy/Update Approval
                    </span>
                  </NavLink>
                )}
              </li>
              {/* end::2 Level */}
            </ul>
          </div>
        </li>
        {/* end::1 Level */}

        {/* begin::1 Level */}
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/custom"
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="menu-text">Github Release Management</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              {/* begin::2 Level */}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/github-release-page"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/github-release-page">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                    />
                  </span>
                  <span className="menu-text">Github Release Page</span>
                </NavLink>
              </li>
              {/* end::2 Level */}

              {/* begin::2 Level */}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/github-release-page/admin-approval"
                )}`}
                aria-haspopup="true"
              >
                {/* only superadmin is able to view the approval page link */}
                {isSuperadmin && (
                  <NavLink
                    className="menu-link"
                    to="/github-release-page/admin-approval"
                  >
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/Layers.svg"
                        )}
                      />
                    </span>
                    <span className="menu-text">Github Release Approval</span>
                  </NavLink>
                )}
              </li>
              {/* end::2 Level */}
            </ul>
          </div>
        </li>
        {/* end::1 Level */}

        {/* begin::1 Level */}
        <li
          className={`menu-item ${getMenuItemActive("/user-management")}`}
          aria-haspopup="true"
        >
          {/* only superadmin is able to view the user management page link */}
          {isSuperadmin && (
            <NavLink className="menu-link" to="/user-management">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">User Management</span>
            </NavLink>
          )}
        </li>
        {/* end::1 Level */}

        {/* //custom pages */}
      </ul>
      {/* end::Header Nav */}
    </div>
  );
}
