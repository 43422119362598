import React from "react";
import MaterialTable from "material-table";

// dot to show status color
import { TimelineDot } from "@material-ui/lab";

// constant object to represent status, to be used in status column to change status color
const statusColors = {
  InProgress: "orange",
  Building: "yellow",
  Success: "green",
  Failure: "red"
};
const GroupHistoryOverviewTable = props => {
  const returnStatusDot = status => (
    <>
      <p style={{ display: "inline", textAlign: "right" }}>{status}</p>
      <TimelineDot
        style={{
          color: `${statusColors[status]}`,
          background: `${statusColors[status]}`,
          display: "inline-block",
          verticalAlign: "middle",
          marginLeft: "5px"
        }}
      />
    </>
  );

  return (
    <div>
      <MaterialTable
        title="Group History Overview"
        columns={[
          {
            defaultSort: "desc",
            title: "Last Deployed Date & Time",
            field: "deployed",
            align: "center"
          },
          { title: "Latest Version", field: "version", align: "right" },
          {
            title: "Status",
            align: "right",
            render: row => returnStatusDot(row.status)
          }
        ]}
        data={props.data}
        options={{
          padding: "dense",
          pageSize: 25,
          pageSizeOptions: [10, 25, 50]
        }}
      />
    </div>
  );
};

export default GroupHistoryOverviewTable;
