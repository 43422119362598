/* eslint-disable react/jsx-filename-extension */
import React, { useState } from "react";
import { LinearProgress } from "@material-ui/core";
import { useSelector, shallowEqual } from "react-redux";
import { useSubheader } from "../../_metronic/layout";
import useFetch from "../custom_hooks/useFetch";
import useAuthCognitoGroup from "../custom_hooks/useAuthCognitoGroup";
import ServersTable from "../custom_ui_components/ServersTable";
import LoadingDialog from "../custom_ui_components/LoadingDialog";
import ErrorDialog from "../custom_ui_components/ErrorDialog";
import { formatGreengrassDeploymentsData } from "../custom_helpers/tableDataFilters";
import { GREENGRASS_INFO_API } from "../../env";
import DeploymentTasksDropdown from "../custom_ui_components/DeploymentTasksDropdown";
import DumbConfirmationDialog from "../custom_ui_components/DumbConfirmationDialog";
import ModelUpdateDialog from "../server_deployments_/ModelUpdateDialog";

// for server deployments
import ApiPopupDialog from "../custom_ui_components/ApiPopupDialog";
import {
  SERVER_DEPLOYMENTS_API,
  MODEL_UPDATE_IN_S3_API
} from "../server_deployments_/helpers/serverDeploymentsAPI";
import { createRequest } from "../server_deployments_/helpers/serverApiHelpers";
import ModelDeploymentDialog from "../server_deployments_/ModelDeploymentDialog";
import PublishConfigConfirmationDialog from "../custom_ui_components/PublishConfigConfirmationDialog";
import LargeButton from "../custom_ui_components/LargeButton";

const GreengrassPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Server Deployments");
  const { formattedData, isLoading, error } = useFetch(
    GREENGRASS_INFO_API,
    formatGreengrassDeploymentsData
  );

  // determine user's permission
  const isNormalUser = useAuthCognitoGroup("normal-user");
  const isSuperadmin = useAuthCognitoGroup("superadmin");

  // to keep track of selected groups
  const [selectedGroups, setSelectedGroups] = useState([]);

  // api fetch dialog useState hook
  const [showApiPopup, setShowApiPopup] = useState(false);
  const [apiFetching, setApiFetching] = useState(false);
  const [apiResponse, setApiResponse] = useState("");

  // passed down as props to table
  function getSelectedGroups(groups) {
    setSelectedGroups(groups);
  }

  // returns group as JSX (used for presentation purpose in confirmation dialog)
  function getListOfGroupsJSX(groupsArray) {
    // const groupNameArray = groupsArray.map(group => <li>{group.name}</li>);
    return (
      <ul>
        {groupsArray.map(group => (
          <li>{group.name}</li>
        ))}
      </ul>
    );
  }

  // get username from redux store
  const cognitoUser = useSelector(
    state => state.auth.user.username, // dot notation
    shallowEqual
  );

  // sets deployment type
  const [deploymentType, setDeploymentType] = useState("");

  // for server deployments
  const [openConfDialog, setOpenConfDialog] = useState(false);

  function handleOpenDeploymentTasks(depType) {
    setOpenConfDialog(true);
    setDeploymentType(depType);
  }

  function handleCloseDeploymentTasks() {
    setOpenConfDialog(false);
  }

  // for model deployments
  const [openModelUpdateDialog, setOpenModelUpdateDialog] = useState(false);
  const [openModelDeploymentDialog, setOpenModelDeploymentDialog] = useState(
    false
  );

  function handleOpenModelUpdateDialog() {
    setOpenModelUpdateDialog(true);
  }

  function handleCloseModelUpdateDialog() {
    setOpenModelUpdateDialog(false);
  }

  function handleOpenModelDeploymentDialog() {
    setOpenModelDeploymentDialog(true);
  }

  function handleCloseModelDeploymentDialog() {
    setOpenModelDeploymentDialog(false);
  }

  // create server deployment request (that calls the API)
  function handleDeploymentRequest(depType) {
    setApiFetching(true);
    setShowApiPopup(true);
    createRequest("POST", SERVER_DEPLOYMENTS_API, {
      groups: selectedGroups,
      deploymentType: depType,
      requestUser: cognitoUser
    }).then(data => {
      setApiFetching(false);
      if (
        data.ResponseMetadata !== undefined &&
        data.ResponseMetadata.HTTPStatusCode === 200
      ) {
        setApiResponse("Succesfully created deployment request");
      } else {
        setApiResponse("Failed to create deployment request. PLease try again");
      }
    });
  }

  // create model copying request (that calls the API)
  function handleModelCopyRequest(inputPath, outputPath) {
    setApiFetching(true);
    setShowApiPopup(true);
    createRequest("POST", MODEL_UPDATE_IN_S3_API, {
      inputPath,
      outputPath,
      requestUser: cognitoUser
    }).then(data => {
      setApiFetching(false);
      if (
        data.ResponseMetadata !== undefined &&
        data.ResponseMetadata.HTTPStatusCode === 200
      ) {
        setApiResponse("Succesfully created deployment request");
      } else {
        setApiResponse(data.errorMessage);
      }
    });
  }

  // create model deployment + new deployment request (calls the API)
  function handleModelDeploymentRequest(depType, anprS3Uri, vcS3Uri) {
    setApiFetching(true);
    setShowApiPopup(true);
    createRequest("POST", SERVER_DEPLOYMENTS_API, {
      groups: selectedGroups.map(group => ({
        ...group, // spread object properties
        // if any of the two URI field is default value ("s3://"), it means user has not entered a s3 URI, thus will not be added to the group's property
        ...(anprS3Uri !== "s3://" && { anprS3Uri }), // spread operator to conditionally set property
        ...(vcS3Uri !== "s3://" && { vcS3Uri }) // spread operator to conditionally set property
        // refer to: https://stackoverflow.com/a/60492828
      })),
      deploymentType: depType,
      requestUser: cognitoUser
    }).then(data => {
      setApiFetching(false);
      if (
        data.ResponseMetadata !== undefined &&
        data.ResponseMetadata.HTTPStatusCode === 200
      ) {
        setApiResponse("Succesfully created deployment request");
      } else {
        setApiResponse(data.errorMessage);
      }
    });
  }

  return (
    <div>
      <p>This page will contain information about greengrass groups</p>
      {isLoading && (
        <div>
          <LoadingDialog message="Server Deployments data loading..." />
          <LinearProgress />
        </div>
      )}
      {error && (
        <div>
          <ErrorDialog link="/greengrass-page" title="Greengrass Page" />
        </div>
      )}
      {formattedData && (
        <div>
          {/* either devs or superadmin can access Create, Update, Delete features */}
          {(isNormalUser || isSuperadmin) && (
            <>
              <DeploymentTasksDropdown
                disabled={selectedGroups.length === 0}
                selectedGroups={selectedGroups}
                openConfDialog={handleOpenDeploymentTasks}
                openModelUpdateDialog={handleOpenModelUpdateDialog}
                openModelDeploymentDialog={handleOpenModelDeploymentDialog}
              />
              <PublishConfigConfirmationDialog selected={selectedGroups} />
              <br />
              <LargeButton
                variant="contained"
                onClick={handleOpenModelUpdateDialog}
                title="Model Update"
              />
              <br />

              <DumbConfirmationDialog
                open={openConfDialog}
                onClose={handleCloseDeploymentTasks}
                onConfirm={() => {
                  handleDeploymentRequest(deploymentType);
                }}
                title={deploymentType}
                question={`create a "${deploymentType}" request for the following ${selectedGroups.length} groups`}
                text={getListOfGroupsJSX(selectedGroups)}
              />
              <ModelUpdateDialog
                open={openModelUpdateDialog}
                handleClose={handleCloseModelUpdateDialog}
                handleSubmit={handleModelCopyRequest}
              />
              <ModelDeploymentDialog
                open={openModelDeploymentDialog}
                handleClose={handleCloseModelDeploymentDialog}
                handleSubmit={handleModelDeploymentRequest}
                text={getListOfGroupsJSX(selectedGroups)}
              />
            </>
          )}
          <ServersTable
            data={formattedData}
            getSelectedGroups={getSelectedGroups}
            selectedGroups={selectedGroups}
          />
          {showApiPopup && (
            <ApiPopupDialog
              open={showApiPopup}
              onClose={() => {
                setShowApiPopup(false);
              }}
              apiFetching={apiFetching}
              completeMessage={apiResponse}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default GreengrassPage;
