import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useSelector, shallowEqual } from "react-redux";
import { GITHUB_RELEASE_ADD_API } from "../../env";

import PermissionDeniedDialog from "./permissionDeniedDialog";
// import custom auth hook
import useAuthCognitoGroup from "../custom_hooks/useAuthCognitoGroup";

export default function GithubRequestFormDialog(props) {
  // gets cognito username
  const cognitoUser = useSelector(
    state => state.auth.user.username, // dot notation
    shallowEqual
  );

  // determines user permission based on cognito group
  const isSuperadmin = useAuthCognitoGroup("superadmin");
  const isNormalUser = useAuthCognitoGroup("normal-user");
  const permissionAllowed = isSuperadmin || isNormalUser;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // initialValues
  const initialValues = {
    repository: `${props.title}`,
    tagVersion: "",
    targetBranch: "master",
    releaseTitle: "",
    releaseBody: "",
    author: `${cognitoUser}`
  };

  const [values, setValues] = useState(initialValues);

  function createRequest() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values)
    };
    fetch(GITHUB_RELEASE_ADD_API, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
      });
  }

  const handleSubmit = () => {
    console.table(values);
    createRequest();
    // resets form values after making POST request
    setValues(initialValues);
  };

  // function to handle input change
  const handleInputChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Create Release Request
      </Button>
      {permissionAllowed && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          {/* <form onSubmit={handleSubmit}> */}
          <DialogTitle id="form-dialog-title">Create Release</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create a Release Request, fill in the information required
              below:
            </DialogContentText>
            <TextField
              margin="dense"
              id="repository"
              label="Repository"
              type="text"
              value={values.repository}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="tag"
              label="Tag Version"
              type="text"
              name="tagVersion"
              fullWidth
              value={values.tagVersion}
              onChange={handleInputChange}
              required
            />
            <TextField
              margin="dense"
              id="branch"
              label="Target Branch (eg: master)"
              type="text"
              name="targetBranch"
              fullWidth
              value={values.targetBranch}
              onChange={handleInputChange}
              required
            />
            <TextField
              margin="dense"
              id="title"
              label="Release Title"
              type="text"
              name="releaseTitle"
              fullWidth
              value={values.releaseTitle}
              onChange={handleInputChange}
              required
            />
            <TextField
              margin="dense"
              id="body"
              label="Release body (Describe the release)"
              type="text"
              name="releaseBody"
              fullWidth
              multiline
              value={values.releaseBody}
              onChange={handleInputChange}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleClose();
                handleSubmit();
                // refresh page to re-query latest data from db
                window.location.reload(false);
              }}
              color="primary"
            >
              Create Request
            </Button>
          </DialogActions>
          {/* </form> */}
        </Dialog>
      )}

      {/* displayed instead if permission not granted to user's role */}
      {!permissionAllowed && (
        <PermissionDeniedDialog
          open={open}
          onClose={() => {
            handleClose();
          }}
        />
      )}
    </div>
  );
}
