import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined
};

//this is the auth reducer that takes in actions and state, and update the state
export const reducer = persistReducer(
  { storage, key: "v713-demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {

      // case actionTypes.Login: {
      //   const { authToken } = action.payload;

      //   //console logging the auth token to see how it looks like:
      //   console.log('from authRedux.js: ', 'authToken is:', authToken)
      //   return { authToken, user: undefined }; //return auth token? what about doing something with the state
      // }

      // case actionTypes.Register: {
      //   const { authToken } = action.payload;

      //   return { authToken, user: undefined };
      // }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed. (Why? email metronic if in doubt)
        return initialAuthState;
      }

      // case actionTypes.UserLoaded: {
      //   const { user } = action.payload;
      //   return { ...state, user };
      // }

      // case actionTypes.SetUser: {
      //   const { user } = action.payload;
      //   return { ...state, user };
      // }


      //Self created ognito action type here 
      case '[Cognito] Log in' : {
        const { user } = action.payload;
        return { ...state,user }
      }

      default:
        return state;
    }
  }
);

export const actions = { //these are actions creators (function that returns an action object that has type and payload)
  // login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  // register: authToken => ({
  //   type: actionTypes.Register,
  //   payload: { authToken }
  // }),
  logout: () => ({ type: actionTypes.Logout }),
  // requestUser: user => ({
  //   type: actionTypes.UserRequested,
  //   payload: { user }
  // }),
  // fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  // setUser: user => ({ type: actionTypes.SetUser, payload: { user } }),

  //Cognito login action creator
  cognitoLogin : ( user ) => ({ type:'[Cognito] Log in', payload: { user } }) 

};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });
}
