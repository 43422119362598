// 5 form fields: path, role, password, confirm password, email.

import React from "react";
import { useFormik } from "formik";
import { TextField, Button, Select } from "@material-ui/core";

// placeholder will remove
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ModelUpdateDialog = props => {
  const ANPR_PATH =
    "s3://plus-sagemaker-models/plus-vehicle-track-model/model.tar.gz";
  const VC_PATH = "s3://plus-sagemaker-models/plus-ocr-model/ocr.tar.gz";

  const formik = useFormik({
    initialValues: {
      inputPath: "",
      outputPath: ""
    },

    validate: values => {
      const errors = {};

      if (!values.inputPath) {
        errors.inputPath = "Required";
      } else if (!values.inputPath.startsWith("s3://")) {
        errors.inputPath = "S3 URI must start with 's3://' ";
      }

      if (!values.outputPath) {
        errors.outputPath = "Required";
      } else if (!values.outputPath.startsWith("s3://")) {
        errors.outputPath = "S3 URI must start with 's3://' ";
      }

      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      // alert(JSON.stringify(values, null, 2));
      props.handleSubmit(values.inputPath, values.outputPath);
      // resets form after submitting
      resetForm();

      // handleclose
      props.handleClose();
    }
  });
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Model Update</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <DialogContentText>
            Create Model Updating Request below.
          </DialogContentText>
          <div>
            <TextField
              id="inputPath"
              label="Input Path"
              name="inputPath"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.inputPath}
              onBlur={formik.handleBlur}
              fullWidth
              margin="dense"
            />
            {formik.touched.inputPath && formik.errors.inputPath ? (
              <div>{formik.errors.inputPath}</div>
            ) : null}
          </div>
          <br />
          <Select
            native
            name="outputPath"
            value={formik.values.outputPath}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
          >
            <option aria-label="None" value="">
              Select an S3 path to copy to
            </option>
            <option value={VC_PATH}>VC ({VC_PATH})</option>
            <option value={ANPR_PATH}>ANPR ({ANPR_PATH})</option>
          </Select>
          {formik.touched.outputPath && formik.errors.outputPath ? (
            <div>{formik.errors.outputPath}</div>
          ) : null}
          <div />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.handleClose();
              formik.resetForm();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!(formik.isValid && formik.dirty && formik.touched)}
            onClick={props.handleClose}
          >
            Create Model Deployment Request
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModelUpdateDialog;
