import React from "react";
import { useParams } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import { useSubheader } from "../../_metronic/layout";
import useFetch from "../custom_hooks/useFetch";
import LoadingDialog from "../custom_ui_components/LoadingDialog";
import ErrorDialog from "../custom_ui_components/ErrorDialog";
import { formatIndividualGroupDeploymentsData } from "../custom_helpers/tableDataFilters";
import { GREENGRASS_GROUP_HISTORY_API } from "../../env";
import GroupHistoryOverviewTable from "../custom_ui_components/GroupHistoryOverviewTable";

const GreengrassHistoryPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Group Deployment History");
  const { id } = useParams();
  const { formattedData, isLoading, error } = useFetch(
    GREENGRASS_GROUP_HISTORY_API + id,
    formatIndividualGroupDeploymentsData
  );

  return (
    <div>
      <p>This page show deployment history of group ID: {id}</p>
      {isLoading && (
        <div>
          <LoadingDialog message="Greengrass Group Deployments History loading..." />
          <LinearProgress />
        </div>
      )}
      {error && (
        <div>
          <ErrorDialog
            link={`/greengrass-page/${id}`}
            title={`Group ID: ${id}`}
          />
        </div>
      )}
      {formattedData && <GroupHistoryOverviewTable data={formattedData} />}
    </div>
  );
};

export default GreengrassHistoryPage;
