export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState(); //gets state from store

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
        
      }
      // i will console log the response to hopefully understand the entire flow:
      console.log('setupAxios.js:', 'config returned is:', config)
      return config;
    },
    err => Promise.reject(err)
  );
}
