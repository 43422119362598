import moment from "moment";

// format Greengrass Servers Deployments Data
function formatGreengrassDeploymentsData(fetchedData) {
  const formattedData = [];

  fetchedData.forEach(group => {
    // adds data to filtered data array
    formattedData.push({
      name: group.Name,
      groupId: group.Id,
      deployed:
        group.DeploymentHistory.length > 0
          ? // converts ISO time format to a more readable local time strubg
            moment(group.DeploymentHistory[0].CreatedAt).format(
              "YYYY-MM-DD, HH:mm:ss"
            )
          : "No Deployments Yet",
      version: group.LatestVersion
    });
  });
  return formattedData;
}

// format Individual Greengrass Server Deployments Data
function formatIndividualGroupDeploymentsData(fetchedData) {
  const formattedData = [];

  fetchedData.forEach(deployment => {
    formattedData.push({
      deployed: moment(deployment.Deployed).format("YYYY-MM-DD, HH:mm:ss"),
      version: deployment.LatestVersion,
      status: deployment.Status
    });
  });
  return formattedData;
}

// format Github History of Releases Data
function formatGithubReleasesIndividualRepoData(fetchedData) {
  const formattedData = [];

  fetchedData.forEach(release => {
    formattedData.push({
      tag: release.tagVersion,
      title: release.title,
      targetBranch: release.targetBranch,
      body: release.body,
      releasedAt: release.releasedAt,
      author: release.author
    });
  });
  return formattedData;
}

function formatCognitoUsersData(fetchedData) {
  const formattedData = [];
  let counter = 0;
  fetchedData.forEach(user => {
    counter += 1;
    formattedData.push({
      // number, username, role, userStatus, enabled, emailAddress
      number: counter,
      username: user.Username,
      role: user.Attributes[2].Value,
      userStatus: user.UserStatus,
      enabled: user.Enabled.toString(),
      email: user.Attributes[3].Value
    });
  });
  return formattedData;
}

export {
  formatGreengrassDeploymentsData,
  formatIndividualGroupDeploymentsData,
  formatGithubReleasesIndividualRepoData,
  formatCognitoUsersData
};
