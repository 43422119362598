import React from "react";
import { useFormik } from "formik";
import { TextField, Button, Select } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ModelDeploymentDialog = props => {
  const formik = useFormik({
    initialValues: {
      anprS3Uri: "s3://",
      vcS3Uri: "s3://"
    },
    validate: values => {
      const errors = {};

      if (!values.anprS3Uri) {
        errors.anprS3Uri = "Required";
      } else if (!values.anprS3Uri.startsWith("s3://")) {
        errors.anprS3Uri = "S3 URI must start with 's3://' ";
      }
      // else if (
      //   !(
      //     values.anprS3Uri.endsWith(".zip") ||
      //     values.anprS3Uri.endsWith(".tar.gz")
      //   )
      // ) {
      //   errors.anprS3Uri = "S3 URI must end with either '.zip' or '.tar.gz' ";
      // }

      if (!values.vcS3Uri) {
        errors.vcS3Uri = "Required";
      } else if (!values.vcS3Uri.startsWith("s3://")) {
        errors.vcS3Uri = "S3 URI must start with 's3://' ";
      }

      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      // alert(JSON.stringify(values, null, 2));
      console.log(values);
      props.handleSubmit(
        "ModelAndNewDeployment",
        values.anprS3Uri,
        values.vcS3Uri
      );
      // resets form after submitting
      resetForm();

      // handleclose
      props.handleClose();
    }
  });
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Model + New Deployment</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <DialogContentText>
            Create Model Deployment + New Deployment Request for the following
            Greengrass Groups:
            <br />
            {props.text}
          </DialogContentText>
          <div>
            <TextField
              id="anprS3Uri"
              label="ANPR MODEL S3 URI"
              name="anprS3Uri"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.anprS3Uri}
              onBlur={formik.handleBlur}
              fullWidth
              margin="dense"
            />
            {formik.touched.anprS3Uri && formik.errors.anprS3Uri ? (
              <div>{formik.errors.anprS3Uri}</div>
            ) : null}

            <TextField
              id="vcS3Uri"
              label="VC MODEL S3 URI"
              name="vcS3Uri"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vcS3Uri}
              onBlur={formik.handleBlur}
              fullWidth
              margin="dense"
            />
            {formik.touched.vcS3Uri && formik.errors.vcS3Uri ? (
              <div>{formik.errors.vcS3Uri}</div>
            ) : null}
          </div>
          <br />
          <div />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.handleClose();
              formik.resetForm();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!(formik.isValid && formik.dirty)}
            onClick={props.handleClose}
          >
            Create Model Deployment Request
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModelDeploymentDialog;
