import React from "react";
import MaterialTable from "material-table";

const SuperTable = props => (
  <div>
    <MaterialTable
      columns={[
        { title: "Tag Version", field: "tag" },
        { title: "Title", field: "title" },
        { title: "Target Branch", field: "targetBranch" },
        { title: "Body", field: "body" },
        {
          title: "Released At",
          field: "releasedAt"
        },
        { title: "Author", field: "author" }
      ]}
      data={props.data}
      title="History of Releases"
    />
  </div>
);

export default SuperTable;
