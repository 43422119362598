import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as auth from "../_redux/authRedux";

// cognito packages
import UserPool from "../../../custom_cognitoAuth/UserPool";

const initialValues = {
  verificationCode: "",
  email: "",
  username: "",
  password: "",
  changepassword: "",
  acceptTerms: false
};

function ResetPassword(props) {
  const { intl } = props;
  const [isReset, setIsReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    verificationCode: Yup.string()
      .min(6, "The verification code is 6 digits")
      .max(6, "The verification code is 6 digits")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    email: Yup.string()
      .email("Wrong email format")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 8 characters")
      .max(50, "Maximum 30 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must contain atleast 8 Characters, One Uppercase, One Lowercase, One Number and one special case character"
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
      .when("password", {
        is: val => !!(val && val.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        )
      })
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      const user = new CognitoUser({
        Username: values.email,
        Pool: UserPool
      });

      user.confirmPassword(values.verificationCode, values.password, {
        onSuccess: data => {
          console.log("onSuccess:", data);
          disableLoading();
          setSubmitting(false);
          setIsReset(true);
        },
        onFailure: err => {
          console.error("onFailure:", err);
          setSubmitting(false);
          setStatus(err.message);
          disableLoading();
        }
      });
    }
  });

  return (
    <>
      {isReset && <Redirect to="/auth/login" />}
      {!isReset && (
        <div className="login-form login-signin" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Reset Password</h3>
            <p className="text-muted font-weight-bold">
              Enter the verification code sent to your email and a new password.
            </p>
          </div>

          <form
            id="kt_login_signin_form"
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            onSubmit={formik.handleSubmit}
          >
            {/* begin: Alert */}
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            {/* end: Alert */}

            {/* begin: Verification Code */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Verification Code"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "verificationCode"
                )}`}
                name="verificationCode"
                {...formik.getFieldProps("verificationCode")}
              />
              {formik.touched.verificationCode &&
              formik.errors.verificationCode ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.verificationCode}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: Verification Code */}

            {/* begin: Email */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            {/* end: Email */}

            {/* begin: Password */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="New Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            {/* end: Password */}

            {/* begin: Confirm Password */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Confirm Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "changepassword"
                )}`}
                name="changepassword"
                {...formik.getFieldProps("changepassword")}
              />
              {formik.touched.changepassword && formik.errors.changepassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.changepassword}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: Confirm Password */}

            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                <span>Submit</span>
                {loading && <span className="ml-3 spinner spinner-white" />}
              </button>

              <Link to="/auth/login">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
