import React from "react";
import MaterialTable from "material-table";

const ModelUpdateApprovalTable = props => (
  <div>
    <MaterialTable
      columns={[
        { title: "Request ID", field: "requestId" },
        { title: "Input Path", field: "inputPath" },
        { title: "Output Path", field: "outputPath" },
        { title: "Requested At", field: "requestedAt" },
        { title: "Request User", field: "requestUser" }
      ]}
      data={props.data}
      title="S3 Bucket Model Copying Requests"
      actions={
        props.permissionAllowed && [
          // only superadmin have access to create and reject actions

          {
            icon: "check",
            tooltip: "Approve",
            onClick: (event, rowData) => {
              props.handleApproveAction(rowData.requestId);
            }
          },
          {
            icon: "close",
            tooltip: "Reject",
            onClick: (event, rowData) => {
              props.handleRejectAction(rowData.requestId);
            }
          }
        ]
      }
      options={{
        actionsColumnIndex: -1
      }}
    />
  </div>
);
export default ModelUpdateApprovalTable;
