import React from "react";
import { Paper } from "@material-ui/core";
import { Link } from "react-router-dom";

const ErrorDialog = props => {
  // add some themes here

  return (
    <div>
      <Paper>
        <h1>Error fetching Data. Return to Homepage:</h1>
        <Link to="/">Home Page</Link>
        <h1>Or refresh the page again:</h1>
        <Link to={props.link} onClick={() => window.location.reload()}>
          {props.title}
        </Link>
      </Paper>
    </div>
  );
};

export default ErrorDialog;
