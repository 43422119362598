
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

import LargeButton from "./LargeButton";

export default function DumbConfirmationDialog(props) {
  return (
    <div>
      {/* Dialog Component that makes API call if user selects confirm  */}
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {props.question} ?
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            {props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LargeButton
            title="No, Cancel the Action"
            variant="outlined"
            onClick={props.onClose} // this just closes the dialog
          />
          <LargeButton
            title="Yes, Proceed"
            variant="outlined"
            onClick={() => {
              props.onClose(); // this closes the dialog.
              props.onConfirm(); // this calls a props function.
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
